@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

.parent-div {
  height: 100%;
  margin: 0;
  display: none;
  justify-content: center;
  .main-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    border: 1px solid grey;
    margin: 30px;
    padding: 50px;
    border-radius: 6px;
    max-width: 1100px;
    background-color: #eaeaea;

    .strategy-title {
      .title {
        font-weight: 700;
        font-family: "Nunito", sans-serif;
      }
      margin-bottom: 50px;
    }
    .strategy-form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      h6 {
        font-weight: 700;
        font-size: 22px;
        font-family: "Nunito", sans-serif;
      }
      .general-information {
        display: flex;
        flex-direction: row;
        .strategy-image {
          width: 170px;
          .circle {
            width: 144px; /* Ancho del círculo */
            height: 145px; /* Alto del círculo */
            border-radius: 50%; /* Hace que el div sea redondo */
            background-color: lightblue; /* Color de fondo */
            display: flex;
            justify-content: center; /* Centra horizontalmente el contenido */
            align-items: center; /* Centra verticalmente el contenido */
            text-align: center;
          }

          .circle-text {
            font-size: 16px;
            color: darkblue;
          }
        }
        .general-information-form {
          border-right: 1px solid #949494;
        }
      }
      .strategy-settings-column {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 360px;
      }
    }
  }
}

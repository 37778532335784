.containerOrders-open {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media (min-width: 1120px) {
    max-width: 100%;
  }
}
.containerOrders-closed {
  width: 0%;
  display: none;
}

.containerProfile-open {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media (min-width: 1120px) {
    max-width: 100%;
  }
}
.containerProfile-closed {
  width: 0%;
  display: none;
}

// Settings form styles
.formContainer-open {
  height: 100%;
  width: calc(100% - 15px);
  margin: 10px;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: 2px solid var(--bg-left);
  //box-shadow: 1px 0 3px #999BA3;

  button {
    width: 190px;
  }
}

.formContainer-closed {
  width: 0;
  .settingForm {
    width: 0;
    display: none;
  }
}

.settingForm {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  width: 100%;
  input {
    width: 190px;
  }
  select {
    width: 190px;
  }

  .settingLabel {
    padding-left: 10px * 2;
    padding-bottom: 1px;
    padding-top: 10px;
    color: #363636;
    font-size: 14px;
  }
}

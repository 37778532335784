.mainInputStyle {
  height: 35px;
  background: none;
  width: 95%;
  color: #363636;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 5px;
}
.mainCheckBoxInputStyle {
  border-radius: 10px;
  height: 15px;
  background: none;
  width: 15px;
}

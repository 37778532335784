.containerSignalProfile-open {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  .formContainer {
    width: 1207px;
    margin: 0;
    .profile-social-container {
      height: 378px;
      margin: 10px 20px 5px 20px;
      display: flex;
      flex-direction: row;
      .profile-container {
        background-color: #eaeaea;
        width: 70%;
        margin: 10px 10px 5px 0px;
        border-radius: 18px;
        position: relative;
      }
      .social-container {
        background-color: #eaeaea;
        width: 30%;
        margin: 10px 0px 5px 10px;
        border-radius: 18px;
        position: relative;
      }
    }

    .statistical-trades-container {
      height: 418px;
      margin: 10px 20px 10px 20px;
      background-color: #eaeaea;
      display: flex;
      flex-direction: row;
      border-radius: 18px;
      .statistical-container {
        width: 80%;
        margin: 20px 20px 10px 20px;
      }
      .totalTrades-container {
        width: 20%;
        margin: 20px 20px 10px 20px;
      }
    }

    .settings-logs-container {
      height: 418px;
      margin: 10px 20px 10px 20px;
      background-color: #eaeaea;
      border-radius: 18px;
      display: flex;
      flex-direction: row;
    }
  }
}
.containerSignalProfile-closed {
  width: 0%;
  display: none;
}

// Settings form styles
.formContainer-open {
  height: 100%;
  width: calc(100% - 15px);
  margin: 10px;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: 2px solid var(--bg-left);
  //box-shadow: 1px 0 3px #999BA3;

  button {
    width: 190px;
  }
}
.formContainer-closed {
  width: 0;
  .settingForm {
    width: 0;
    display: none;
  }
}

.settingForm {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  width: 100%;
  input {
    width: 190px;
  }
  select {
    width: 190px;
  }

  .settingLabel {
    padding-left: 10px * 2;
    padding-bottom: 1px;
    padding-top: 10px;
    color: #363636;
    font-size: 14px;
  }
}

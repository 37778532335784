.wrap-error {
  background-attachment: fixed;
  background-size: cover;
  height: calc(var(--vh, 1vh) * 100);
}
.wrap-error h1 {
  font-size: 6.875rem;
  letter-spacing: -13px;
  line-height: 1;
  font-family: montserrat, sans-serif;
}
h1 span {
  text-shadow: -8px 0 0 rgb(1, 3, 17);
}
.text-9xl {
  font-size: 5.5rem;
}

@import "./mixins";
@import "./variables";
@import "./extensions";

:root {
  --landscape-width: 20; // % OR vw for responsive window resizing!
  --portrait-width: 80vw;
  --header-height: 5rem;
  --secs: 0.4s;
  --bg-left: #161f2a;
  --bg-right: cyan;
  --bg-main: #ffffff;
  --sidebar-width: 300px;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
}

#layout {
  display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout
  // Style in common for sidebars and main area:
  .header {
    position: relative;
    height: var(--header-height);
    color: #ffffff;

    .title {
      position: absolute;
      right: 0;
      font-size: 13px;
    }

    .imageTitle {
      display: block;
      width: 201px;
      margin-left: 0px;
      padding-top: 25px;
    }
  }

  .react-switch {
    align-self: center;
    padding-left: 10px;
  }

  .content {
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));

    .userData {
      height: 25%;
      border-bottom: 1px solid black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 25px;
      font-size: 13px;
      color: #f5f5f5;

      .userLogin {
        display: flex;
        flex-direction: row;
        padding: 5px 0;
      }

      .imageLogin {
        padding-top: 5px;
        padding-right: 10px;
      }
    }

    .userSettings {
      display: flex;
      flex-direction: column;
      padding-left: 25px;
      height: 75%;
      box-shadow: 0px -3px 2px 2px #000000b7;
      background: #3c3e48;

      button {
        text-align: start;
        background: none;
        border: none;
        margin-top: 25px;
        font-size: 18px;
        color: #999ba3;

        &:hover {
          color: #f5f5f5;
        }
      }

      .setting {
        &.ON {
          color: white;
        }
      }
    }
  }
}
// Main area specific style:
.main {
  background: var(--bg-main);
  flex-grow: 1;
  outline: 1px solid red;
  width: 100%;

  &.left-open {
    width: calc(100% - 300px);
  }

  .header {
    background: linear-gradient(to right, #800000, #ff0000);

    .title {
      transition: left var(--secs);
      margin-top: 14px;
      left: 0;

      &.left-closed {
        left: var(--header-height);
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5px;
      }
    }

    @media (orientation: landscape) {
      .title {
        transition: left var(--secs), right var(--secs);

        .statusData {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          padding: 18px 40px;
          margin-left: 45px;
          font-size: 14px;

          div {
            border-right: 1px solid white;
            padding: 0 20px 1px 5px;
          }
        }

        &.left-closed {
          left: var(--header-height);
        }

        &.right-closed {
          right: var(--header-height);
        }
      }
    }

    .reloadIcon {
      position: absolute;
      top: 20%;
      right: 0;
      background: none;
      padding: 0 5px;
      margin: 0;
      border: none;
    }
  }

  .contentMain {
    display: flex;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    overflow: auto;
  }
}

// Style in common for both sidebars:
#left,
#right {
  transition: width var(--secs);
  width: 0;
  box-shadow: 1px 0 1px 1px black;

  @media (orientation: landscape) {
    &.open {
      width: var(--sidebar-width);
    }
  }

  @media (orientation: portrait) {
    &.open {
      width: var(--sidebar-width);
    }
  }

  @media (max-width: 550px) {
    &.open {
      width: 100vw;
    }
  }
  // <-- Landscape ONLY sidebars
  .icon {
    position: fixed;
    z-index: 10;
    width: var(--header-height);
    height: var(--header-height);
    line-height: var(--header-height);
    font-size: 3rem;
    text-align: center;
    user-select: none;
    cursor: pointer;
    color: white;
    border-bottom: 1px solid #2e4057;
    background: var(--bg-left);
  }

  .sidebar {
    transition: transform var(--secs); // <-- Portrait AND landscape!
    color: #ffffff;

    @media (max-width: 650px) {
      &.open {
        width: 100vw;

        .header {
          width: 100vw;
        }
      }
    }

    @media (orientation: portrait) {
      width: var(--sidebar-width);
    }

    @media (orientation: landscape) {
      width: var(--sidebar-width);
    }

    .header {
      justify-content: center;
      align-items: center;
      width: calc(var(--sidebar-width) - var(--header-height));
      border-bottom: 1px solid #2e4057;
    }
  }
}

// Left sidebar specific style:
#left {
  z-index: 5;

  .sidebar {
    background: var(--bg-left);

    &.closed {
      transform: translateX(-100%);
    }
    // <-- Left portrait AND landscape!
    .header {
      left: var(--header-height);
    }
  }
}

// Right sidebar specific style:
#right {
  .icon {
    right: 0;
  }

  .sidebar {
    background: var(--bg-right);

    @media (orientation: portrait) {
      &.open {
        transform: translateX(-100%);
      }
    }
    // <-- Right portrait sidebar ONLY!
  }
}

// Settings form styles
.formContainer-open {
  height: 50%;
  width: 100%;
  border-right: 1px solid #999ba3;
  //box-shadow: 1px 0 3px #999BA3;

  @media (max-width: 550px) {
    width: 100%;
  }
  @media (min-width: 551px) and (max-width: 1030px) {
    width: 100%;
  }
  @media (min-width: 1031px) {
    width: 100%;
  }
}
.formContainer-closed {
  width: 0;
  .titleSetting {
    width: 0;
    display: none;
  }
  .settingForm {
    width: 0;
    display: none;
  }
}
.followFormOverlay-open {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
}
.followFormOverlay-closed {
  width: 0;
  .formFollow-closed {
    width: 0;
    display: none;
    .settingFormFollow {
      width: 0;
      display: none;
    }
  }
}
.formFollow-open {
  width: 40%;
  border-radius: 8px;
  border: 2px solid #999ba3;
  box-shadow: 1px 0 3px #999ba3;
  background-color: white;

  @media (max-width: 550px) {
    width: 90vw;
  }
  @media (min-width: 551px) and (max-width: 1030px) {
    width: 305px;
  }
  @media (min-width: 1031px) {
    width: 25vw;
  }
  title {
    display: flex;
    font-size: 20px;
    justify-content: space-around;
    margin-top: 30px;
  }
  .ActionPanel {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    button {
      border: 0px;
      @extend .mainInputStyle;
      margin-top: $main-margin;
      margin-left: $main-margin;
      margin-bottom: $main-margin * 2;
      font-size: 14px;
      color: white;
      background-color: #d33;
      cursor: pointer;

      &:active {
        opacity: 50%;
      }

      &:hover {
        filter: invert(10%);
      }

      @include media-max-width() {
        margin-top: 10px;
        margin-bottom: $main-margin;
      }
    }

    .agreeButton {
      box-shadow: 0px 0px 10px #0000001a;
      color: white;
      background-color: #3085d6;
    }
  }
}
.settingFormFollow {
  display: -ms-grid;
  display: grid;
  background-color: white;
  width: 90%;
  border-radius: 8px;
  margin-left: 4%;

  .settingLabel {
    padding-left: $main-margin * 2;
    padding-bottom: 1px;
    padding-top: $main-margin;
    color: #363636;
    font-size: 14px;
  }
}
.settingForm {
  display: grid;
  background-color: white;
  width: 100%;

  .settingLabel {
    padding-left: $main-margin * 2;
    padding-bottom: 1px;
    padding-top: $main-margin;
    color: #363636;
    font-size: 14px;
  }
}
.titleContainer {
  display: flex;
  justify-content: space-between;
}
.titleSetting {
  display: flex;
  padding-left: 18px;
  padding-left: 18px;

  title {
    font-size: 15px;
    margin-top: $main-margin;
    margin-bottom: calc($main-margin/2);
    color: #363636;
    display: block;
    padding-left: 10px;
    padding-bottom: 5px;
  }
  img {
    width: 27px;
  }
}

input {
  @include input-placeholder();
  padding-left: 10px;
}

.input {
  @extend .mainInputStyle;
  border: 1px solid #c1c3c9;
  margin-left: $main-margin;
  height: 40px;
}
select {
  @include input-placeholder();
  padding-left: 10px;
}
.dinamicInput {
  &.noShow {
    display: none;
  }
}

.loginBtn {
  @extend .mainInputStyle;
  border: 2px solid red;
  margin-top: $main-margin;
  margin-left: $main-margin;
  margin-right: $main-margin * 2;
  margin-bottom: calc($main-margin/4);
  font-size: 13px;
  color: white;
  background-color: red;
  cursor: pointer;

  &:active {
    opacity: 50%;
  }

  &:hover {
    filter: invert(10%);
  }

  &.follower {
    margin-top: $main-margin * 2;
    margin-bottom: $main-margin * 2;
  }
}
#deleteBtn {
  background-color: #ebebeb;
  color: #999ba3;
  border: 2px solid gray;
}
.ddSwitch {
  display: flex;
  flex-direction: row;
  margin-top: $main-margin * 2;
  margin-left: $main-margin * 2;
  margin-bottom: $main-margin * 2;
  &.noShow {
    display: none;
  }
}
.checkBoxInput {
  @extend .mainCheckBoxInputStyle;
  border: 3px solid #000;
  margin-top: $main-margin * 2;
  margin-left: $main-margin * 2;
  margin-bottom: $main-margin * 2;
}
.checkLabel {
  color: GrayText;
  font-size: 14px;
}
.backIcon {
  background: none;
  border: none;
  margin-right: 10px;
}
.linkReactivate {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  text-decoration: underline;
  text-decoration-color: GrayText;

  &:active {
    opacity: 50%;
  }

  &:hover {
    filter: invert(50%);
  }
}
.reActivate {
  margin-right: $main-margin;
  color: #999ba3;
  border: none;
  background: none;
}
.labelField {
  img {
    width: 16px;
    margin-left: 5px;
    margin-bottom: 1px;
  }
}
.disableMessage {
  display: flex;
  align-self: center;
  width: 80%;
  font-size: 12px;
  margin-top: 10%;
  margin-left: $main-margin * 3;
  margin-right: $main-margin * 3;
  text-decoration-color: GrayText;
  text-align: center;
}
.followMessageContainer {
  display: flex;
}
.followParametersMessage {
  width: 80%;
  font-size: 12px;
  margin-top: 10px;
  text-decoration-color: GrayText;
  text-align: center;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  opacity: 80%;
  font-size: 10px;
  color: white;
  z-index: 200;
}
